var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pa-3"},[_c('v-card',[_c('v-card-title',[_vm._v(" Last Modified Incidents ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"items-per-page":15,"footer-props":_vm.footer_options,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.modified",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.modifiedFormat(item.modified))+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.modifiedFormat(item.created))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(" mdi-eye ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.getConfirm(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.TypeOfAnimal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.splitSpecies(item.TypeOfAnimal))+" ")]}}])})],1),_c('v-dialog',{staticStyle:{"z-index":"1301"},attrs:{"persistent":"","width":"350","ripple":_vm.foo},model:{value:(_vm.deletedialog),callback:function ($$v) {_vm.deletedialog=$$v},expression:"deletedialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"justify-center mb-2 rounded-0",attrs:{"color":"warning"}},[_c('v-toolbar-title',[_vm._v("Confirm Incident Delete")])],1),_c('v-card-text',[_c('p',[_vm._v("You are about to delete an Incident. If you are sure, type the following string:")]),_c('p',{staticClass:"justify-center text-center",staticStyle:{"background-color":"#e6e6e6"}},[_c('pre',[_vm._v(_vm._s(_vm.todelete))])]),_c('p',[_vm._v("in the text input below and press confirm.")]),_c('v-form',{ref:"confirmform",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"rules":_vm.confirmRule},model:{value:(_vm.deletecode),callback:function ($$v) {_vm.deletecode=$$v},expression:"deletecode"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.delcancel.apply(null, arguments)}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"warning"},on:{"click":function($event){$event.stopPropagation();return _vm.delconfirm.apply(null, arguments)}}},[_vm._v("Confirm")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }