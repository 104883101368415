<template>
    
        <v-card class="mt-8">
            <v-card-title v-if="!admin">
                Activity for: {{ name }}
            </v-card-title>
            <v-card-title v-else>
              Activity for all users
            </v-card-title>
            <v-data-table 
              :loading="loading"
              :headers="headers" 
              :items="items"
              :server-items-length="total"
              :items-per-page="15"
              :footer-props="footer_options"
              :options.sync="options"
              class="elevation-1"
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              show-expand
            >
            <template v-slot:item.created="{ item }">
              {{ modifiedFormat(item.created) }}
            </template>
            <!-- <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon small class="mr-2" @click="viewItem(item)">
                  mdi-eye
                </v-icon>
            </template> -->
            <template v-slot:expanded-item="{ headers, item}">
                    <td :colspan="headers.length">
                        <v-card class="ma-2">
                            <v-card-title>Activity information</v-card-title>
                            <v-card-text>
                                <v-list>
                                    <v-list-item-content>
                                      <v-list-item-title v-for="(value,key) in flater(item)" :key="`act_${key}`">
                                      <b>{{key}}:</b> {{ value ? value : 'n/a' }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </td>
              </template>
            </v-data-table>
        </v-card>
   
</template>
  
<script>
import { get } from 'vuex-pathify'
import { DateTime } from 'luxon';
import api from '@/services/ActivityService'
import { flattenObject } from '../utils/general';

export default {
  name: "Acitivity",
  props: {
    // name: {
    //     type: String,
    //     default: "User"
    // },
    // items: {
    //     type: Array,
    //     default: []
    // }
  },
  data () {
    return {
      totalItems: 0,
      loading: false,
      singleExpand: false,
      expanded: [],
      options: {
        page: 1,
        itemsPerPage: 10
      },
      footer_options: {},
      serverParams: {
        skip: 0,
        limit: 10
      },
      items: []
    }
  },

  computed: {
    ...get('newauth', {
        admin: 'userProfile@is_superuser',
        name: 'userProfile@email'
    }),
    ...get('activity', {
       admintotal: 'total', 
       usertotal: 'usertotal',
       acts: 'activities',
       useracts: 'userActivities'
    }),
    total () {
        return (this.admin === true) ? this.admintotal : this.usertotal
    },
    storeitems () {
        return (this.admin === true) ? this.acts : this.useracts
    },
    headers () {
      return [
        {
          text: '',
          value: 'data-table-expand'
        },
        {
          text: 'ID',
          align: 'start',
          value: 'id',
        },
        {
          text: "Message",
          value: "message",
        },
        { 
            text: 'Category',
            value: 'category',
        },
        {
            text: 'Level',
            value: 'level',
        },
        {
          text: "User",
          value: "author",
        },
        {
          text: "Created",
          value: "created",
        },
        // { text: 'Actions', value: 'actions', sortable: false },
      ]
    },
  },

  watch: {
    options: {
      async handler() {
        this.fetchData()
      },
      deep: true
    }
  },

  created () {
    // this.$store.dispatch('activity/fetchLastModified')
    this.footer_options['items-per-page-options'] = [10,20,30]
    if (this.admin === true) {
        this.$store.dispatch('activity/fetchTotal')
    } else {
        this.$store.dispatch('activity/fetchUserTotal')
    }
  },
  mounted() {
    this.items = this.storeitems
  },

  methods: {
      flater (item) {
        return flattenObject(item)
      },
      modifiedFormat (item) {
        return DateTime.fromISO(item).toFormat('dd LLL yyyy - HH:mm:ss')
      },
      async fetchData() {
        this.loading = true;

        this.serverParams.skip = (this.options.page - 1) * this.options.itemsPerPage;
        this.serverParams.limit = this.options.itemsPerPage

        try {
          if (this.admin === true) {
            let response = await api.getActivitiesPaginated(
                this.serverParams.skip,
                this.serverParams.limit,
                this.$store.state.newauth.token)
            this.items = response.data
          } else {
            console.log('getting data for user')
            let response = await api.getUserActivitiesPaginated(
                this.serverParams.skip,
                this.serverParams.limit,
                this.$store.state.newauth.token)
            this.items = response.data
            console.log(this.items)
          }
        } catch (error) {
          console.error("Error fetching data:", error)
        } finally {
          this.loading = false
        }
      }
  },
}
</script>


<style scoped>
</style>