<template>
        <v-card class="mt-8">
            <v-card-title>
                Undo Delete to restore Incidents.
            </v-card-title>
            <v-data-table
              :key="updatekey"
              :loading="loading"
              loading-text="Fetching data from server, please wait..."
              :headers="headers" 
              :items="items"
              :items-per-page="15"
              :footer-props="footer_options"
              :options.sync="options"
              class="elevation-1"
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              show-expand
            >
                <template v-slot:item.created="{ item }">
                    {{ modifiedFormat(item.created) }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn @click.prevent="restore(item)">Restore</v-btn>
                </template>

                <template v-slot:expanded-item="{ headers, item}">
                    <td :colspan="headers.length">
                        <v-card class="ma-2">
                            <v-card-title>Deleted Incident Information</v-card-title>
                            <v-card-text>
                                <v-list>
                                    <v-list-item-content>
                                        <v-list-item-title><b>Country:</b> {{ item.data.country }}</v-list-item-title>
                                        <v-list-item-title><b>Region:</b> {{ item.data.region }}</v-list-item-title>
                                        <v-list-item-title><b>Municipality:</b> {{ item.data.municipality }}</v-list-item-title>
                                        <v-list-item-title><b>Date of event:</b> {{ item.data.dateOfEvent }}</v-list-item-title>
                                        <v-list-item-title><b>Dead Animal Found:</b> {{ item.data.deadAnimalFound }}</v-list-item-title>
                                        <v-list-item-title><b>PoisonBaitFound:</b> {{ item.data.poisonBaitFound }}</v-list-item-title>
                                        <v-list-item-title><b>AntipoisonDogUnitUsed:</b> {{ item.data.antipoisonDogUnitUsed }}</v-list-item-title>
                                        <v-list-item-title><b>OfficialComplaint:</b> {{ item.data.officialComplaint }}</v-list-item-title>
                                        <v-list-item-title><b>TypeOfAnimal:</b> {{ item.data.TypeOfAnimal }}</v-list-item-title>
                                        <v-list-item-title><b>Autopsy and Toxicological Analysis:</b> {{ item.data.autopsyAndMore }}</v-list-item-title>
                                        <v-list-item-title><b>Live:</b> {{ item.data.live }}</v-list-item-title>
                                        <v-list-item-title><b>Author:</b> {{ item.data.author }}</v-list-item-title>
                                        <v-list-item-title><b>Modified by:</b> {{ item.data.modifiedby }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </td>
                </template>
            </v-data-table>
        </v-card>
</template>
  
<script>
import { get, call } from 'vuex-pathify'
import { DateTime } from 'luxon';

// new
import api from '@/services/UnDeleteService'

export default {
    name: "UnDelete",
  data () {
    return {
      updatekey: '1',
      totalItems: 0,
      singleExpand: false,
      expanded: [],
      options: {
        page: 1,
        itemsPerPage: 10
      },
      footer_options: {},
      serverParams: {
        skip: 0,
        limit: 10
      }
    }
  },

  computed: {
    ...get('undelete', {
        'items': 'undos',
        'loading': 'loading'
    }),
    headers () {
      return [
        {
            text: '',
            value: 'data-table-expand'
        },
        {
          text: 'ID',
          align: 'start',
          value: 'id',
        },
        {
          text: "Entrycode",
          value: "entrycode",
        },
        {
          text: "Deleted on",
          value: "created"
        },
        { 
          text: 'Actions',
          value: 'actions',
          sortable: false 
        },
      ]
    },
  },
  watch: {
    loading: {
      async handler() {
        this.updatekey = Date.now()
      },
      deep: true
    }
  },

  created () {
    this.fetchUnDelete()
    this.footer_options['items-per-page-options'] = [10,20,30]
  },
  mounted() {
  },

  methods: {
    ...call('undelete', [
        'fetchUnDelete',
        'postUndo',
    ]),
    async restore (item) {
        await this.postUndo(item.id)
        this.fetchUnDelete()
    },
    modifiedFormat (item) {
        return DateTime.fromISO(item).toFormat('dd LLL yyyy - HH:mm:ss')
    },
      
      // new
    //   async fetchData() {
    //     this.loading = true;

    //     this.serverParams.skip = (this.options.page - 1) * this.options.itemsPerPage;
    //     this.serverParams.limit = this.options.itemsPerPage

    //     try {
    //       let response = await api.getLastModifiedPaginated(
    //         this.serverParams.skip,
    //         this.serverParams.limit,
    //         this.$store.state.newauth.token)
    //       this.items = response.data
    //     } catch (error) {
    //       console.error("Error fetching data:", error)
    //     } finally {
    //       this.loading = false
    //     }
    //   }
  },
}
</script>


<style scoped>
</style>