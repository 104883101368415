<template>
  <v-container fluid>
    <last-modified v-if="superadmin" />
    <activities name="Admin" />
    <undelete />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { get } from "vuex-pathify";

import LastModified from '@/components/LastModifiedNew'
import Activity from '@/components/Activity'
import UnDelete from '@/components/UnDelete'


export default {
    components: {
        'last-modified': LastModified,
        'activities': Activity,
        'undelete': UnDelete,
    },
  data() {
    return {
      loading: false,
      select: "",
      items: [
        {country: "Albania", code: "al"},
        {country: "Bosnia", code: "ba"},
        {country: "Bulgaria", code: "bg"},
        {country: "Croatia", code: "hr"},
        {country: "Greece", code: "el"},
        {country: "North Macedonia", code: "mk"},
        {country: "Serbia", code: "rs"},
        {country: "ALL COUNTRIES", code: "all"}
      ]
    };
  },
  computed: {
      ...get('newauth', [
        'userProfile@country',
        'userProfile@is_superuser'
      ]),
      superadmin: get('newauth@userProfile.is_superuser')
  },
  methods: {
  },
  watch: { },
  async created () {
    this.$store.dispatch('activity/fetchActs',this.superadmin)
  }
};
</script>


<style scoped>
</style>