<template>
    <v-row class="pa-3">
        <v-card>
            <v-card-title>
                Last Modified Incidents
            </v-card-title>
            <v-data-table 
              :loading="loading"
              :headers="headers" 
              :items="items"
              :server-items-length="total"
              :items-per-page="15"
              :footer-props="footer_options"
              :options.sync="options"
              class="elevation-1"
            >
            <template v-slot:item.modified="{ item }">
                {{ modifiedFormat(item.modified) }}
            </template>
            <template v-slot:item.created="{ item }">
              {{ modifiedFormat(item.created) }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon small class="mr-2" @click="viewItem(item)">
                  mdi-eye
                </v-icon>
                <v-icon small @click="getConfirm(item)">
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:item.TypeOfAnimal="{ item }">
                {{ splitSpecies(item.TypeOfAnimal) }}
            </template>
            </v-data-table>
        </v-card>

        <!-- START - CONFIRM DELETE DIALOG -->
        <v-dialog
            v-model="deletedialog"
            persistent
            width="350"
            :ripple="foo"
            style="z-index: 1301"
        >
            <v-card>
                <v-toolbar color="warning" class="justify-center mb-2 rounded-0">
                    <v-toolbar-title>Confirm Incident Delete</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <p>You are about to delete an Incident. If you are sure,
                        type the following string:</p>
                    <p class="justify-center text-center" style="background-color: #e6e6e6">
                        <pre>{{ todelete }}</pre>
                    </p>
                    <p>in the text input below and press confirm.</p>
                    <v-form
                        v-model="valid"
                        ref="confirmform"
                        lazy-validation
                    > 
                        <v-text-field
                            v-model="deletecode"
                            :rules="confirmRule"
                        />
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="error" @click.stop="delcancel">Cancel</v-btn>
                    <v-btn color="warning" @click.stop="delconfirm">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- END - CONFIRM DELETE DIALOG -->
    </v-row>
</template>
  
<script>
import { get } from 'vuex-pathify'
import { mapGetters } from "vuex";
import { DateTime } from 'luxon';

// new
import api from '@/services/IncidentService'

export default {
    name: "LastModified",
  data () {
    return {
      items: [],
      totalItems: 0,
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10
      },
      footer_options: {},
      serverParams: {
        skip: 0,
        limit: 10
      },
      // START DELETE DIALOG
      valid: false,
      deletedialog: false,
      foo: false,
      todelete: "",
      deluserid: "",
      deletecode: ""
      // END DELETE DIALOG
    }
  },

  computed: {
    ...mapGetters({
      indexes: "incidentsnew/getLastModified",
    }),
    ...get('incidentsnew',[
      'total'
    ]),
    headers () {
      return [
        {
          text: 'EntryCode',
          align: 'start',
          sortable: true,
          value: 'entrycode',
        },
        {
          text: "Notification Date",
          value: "dateOfEvent",
        },
        { text: 'Country', value: 'country',
        },
        { text: 'Region', value: 'region',
        },
        {
          text: "Municipality",
          value: "municipality",
        },
        {
          text: "Species found dead",
          value: "TypeOfAnimal",
        },
        {
          text: "Created By",
          value: "author"
        },
        {
          text: "Created At",
          value: "created"
        },
        {
          text: "Modified By",
          value: "modifiedby"
        },
        {
            text: "Modified",
            value: "modified"
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ]
    },
    confirmRule() {
        return [
            () => (this.todelete === this.deletecode) || 'Value does not match requested code',
            v => !!v || 'Fill value to confirm'
        ];
    },
  },

  watch: {
    options: {
      async handler() {
        this.fetchData()
      },
      deep: true
    }
  },

  created () {
    this.$store.dispatch('incidentsnew/fetchLastModified')
    this.footer_options['items-per-page-options'] = [10,20,30]
    this.$store.dispatch('incidentsnew/fetchTotal')
  },
  mounted() {
  },

  methods: {
      editItem (item) {
        this.$router.push({name: 'Informant', params: {'entrycode': item.entrycode }})
      },
      viewItem (item) {
        this.$router.push({name: 'ViewIncident', params: {'entrycode': item.entrycode }})
      },
      modifiedFormat (item) {
        return DateTime.fromISO(item).toFormat('dd LLL yyyy - HH:mm:ss')
      },
      splitSpecies (item) {
        if(!item) return 'n/a'
        if (item == '#') {
          return 'n/a'
        }
        return item.split('#').join(', ')
      },
      // new
      async fetchData() {
        this.loading = true;

        this.serverParams.skip = (this.options.page - 1) * this.options.itemsPerPage;
        this.serverParams.limit = this.options.itemsPerPage

        try {
          let response = await api.getLastModifiedPaginated(
            this.serverParams.skip,
            this.serverParams.limit,
            this.$store.state.newauth.token)
          this.items = response.data
        } catch (error) {
          console.error("Error fetching data:", error)
        } finally {
          this.loading = false
        }
      },
      // START CONFIRM DELETE METHODS
      getConfirm (item) {
        this.todelete = `delete ${item.entrycode}`
        this.delentrycode = item.entrycode
        this.deletedialog = true
      },
      delcancel () {
          this.$refs.confirmform.resetValidation()
          this.deletecode = ""
          this.todelete= ""
          this.delentrycode = ""
          this.deletedialog = false
      },
      delconfirm () {
          if (this.$refs.confirmform.validate()) {
              this.deletecode = ""
              // delete the user
              this.$store.dispatch('incidentsnew/deleteIncident', this.delentrycode)
                  .then(console.log(true))
                  .catch(e => {
                      console.log(e)
                  })
                  .finally(
                    this.$store.dispatch('undelete/fetchUnDelete')
                  )
          } else {
              this.foo = true
              console.log('the validation is not correct')
          }
          this.deletecode = ""
          this.delentrycode = ""
          this.$refs.confirmform.resetValidation()
          this.deletedialog = false
      },
      // END CONFIRM DELETE METHODS
  },
}
</script>


<style scoped>
</style>