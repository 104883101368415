var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-8"},[_c('v-card-title',[_vm._v(" Undo Delete to restore Incidents. ")]),_c('v-data-table',{key:_vm.updatekey,staticClass:"elevation-1",attrs:{"loading":_vm.loading,"loading-text":"Fetching data from server, please wait...","headers":_vm.headers,"items":_vm.items,"items-per-page":15,"footer-props":_vm.footer_options,"options":_vm.options,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":""},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.modifiedFormat(item.created))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){$event.preventDefault();return _vm.restore(item)}}},[_vm._v("Restore")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"ma-2"},[_c('v-card-title',[_vm._v("Deleted Incident Information")]),_c('v-card-text',[_c('v-list',[_c('v-list-item-content',[_c('v-list-item-title',[_c('b',[_vm._v("Country:")]),_vm._v(" "+_vm._s(item.data.country))]),_c('v-list-item-title',[_c('b',[_vm._v("Region:")]),_vm._v(" "+_vm._s(item.data.region))]),_c('v-list-item-title',[_c('b',[_vm._v("Municipality:")]),_vm._v(" "+_vm._s(item.data.municipality))]),_c('v-list-item-title',[_c('b',[_vm._v("Date of event:")]),_vm._v(" "+_vm._s(item.data.dateOfEvent))]),_c('v-list-item-title',[_c('b',[_vm._v("Dead Animal Found:")]),_vm._v(" "+_vm._s(item.data.deadAnimalFound))]),_c('v-list-item-title',[_c('b',[_vm._v("PoisonBaitFound:")]),_vm._v(" "+_vm._s(item.data.poisonBaitFound))]),_c('v-list-item-title',[_c('b',[_vm._v("AntipoisonDogUnitUsed:")]),_vm._v(" "+_vm._s(item.data.antipoisonDogUnitUsed))]),_c('v-list-item-title',[_c('b',[_vm._v("OfficialComplaint:")]),_vm._v(" "+_vm._s(item.data.officialComplaint))]),_c('v-list-item-title',[_c('b',[_vm._v("TypeOfAnimal:")]),_vm._v(" "+_vm._s(item.data.TypeOfAnimal))]),_c('v-list-item-title',[_c('b',[_vm._v("Autopsy and Toxicological Analysis:")]),_vm._v(" "+_vm._s(item.data.autopsyAndMore))]),_c('v-list-item-title',[_c('b',[_vm._v("Live:")]),_vm._v(" "+_vm._s(item.data.live))]),_c('v-list-item-title',[_c('b',[_vm._v("Author:")]),_vm._v(" "+_vm._s(item.data.author))]),_c('v-list-item-title',[_c('b',[_vm._v("Modified by:")]),_vm._v(" "+_vm._s(item.data.modifiedby))])],1)],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }